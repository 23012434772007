<template>
  <b-container>
    <b-row>
      <b-col class="mb-4" md="4" v-if="!ifLogin">
        <b-card
          class="border-ibbil-card ibbil-horizantal-line position-relative"
        >
          <ValidationObserver v-slot="{ handleSubmit }" ref="loginForm">
            <b-form
              class="position-relative form-top p-4 login-form"
              @submit.prevent="handleSubmit(login)"
            >
              <h2 class="font-size-20 font-weight-bold mb-3">تسجيل الدخول</h2>
              <div>
                <country-code
                  v-if="!checkIfCheckingUser"
                  :validate="'required|numeric|min:9|max:11'"
                  :placeholder="$t('auth.phoneNumber')"
                  :name="$t('auth.phoneNumber')"
                  :label="$t('auth.phoneNumber')"
                  v-model="userInfo.user"
                  id="phone-code-primary_phone_number"
                  @onSelect="onSelect"
                />
                <input-form
                  v-else
                  class="mb-3"
                  v-model="userInfo.password"
                  :validate="'required'"
                  :placeholder="$t('auth.password')"
                  :name="$t('auth.password')"
                  :type="showPassword ? 'text' : 'password'"
                  @input="validatePassword"
                />
                <i
                  v-if="checkIfCheckingUser"
                  :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
                  @click="togglePasswordVisibility"
                  class="eye-icon position-absolute"
                  :style="
                    isPasswordValid
                      ? 'top: 29%; left: 40px; cursor: pointer;'
                      : 'top: 32%; left: 40px; cursor: pointer;'
                  "
                ></i>
              </div>
              <div class="mb-2">
                <b-button
                  class="text-white w-100 py-2"
                  variant="warning"
                  type="button"
                  v-if="loadingChecking"
                >
                  <spinner-loading
                    :text="checkIfCheckingUser ? 'تحميل' : 'يتم التحقيق'"
                  />
                </b-button>
                <b-button
                  class="text-white w-100 py-2"
                  variant="warning"
                  type="submit"
                  v-else
                >
                  <span
                    >{{ checkIfCheckingUser ? "تسجيل الدخول" : "تحقق" }}
                  </span>
                </b-button>
              </div>
              <div>
                <p class="text-center mt-3 mb-1">
                  ليس لديك حساب ؟
                  <router-link :to="{ name: 'registration' }"
                    ><span class="text-warning"
                      >إنشاء حساب جديد</span
                    ></router-link
                  >
                </p>
              </div>
            </b-form>
          </ValidationObserver>
        </b-card>
      </b-col>
      <b-col class="mb-4" :md="ifLogin ? 12 : 8">
        <b-card class="border-ibbil-card p-3">
          <h2
            class="text-center text-primary font-size-20 font-weight-bold position-relative main-header mb-5"
          >
            {{ $t("endUser.aboutUs") }}
          </h2>
          <p class="font-size-18 text-center m-0">
            {{ $t("endUser.aboutUsInfo") }}
          </p>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
/*eslint-disable */
import authService from "@/modules/auth/services/auth.js";
import { core } from "@/config/pluginInit";
export default {
  data() {
    return {
      isPasswordValid: "",
      userInfo: {
        user: "",
        country_code: "",
        password: "",
      },
      showPassword: false,
      ifLogin: localStorage.getItem("userToken"),
      checkIfCheckingUser: false,
      loadingChecking: false,
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    validatePassword() {
      this.isPasswordValid = !this.userInfo.password;
    },
    loginUser() {
      this.loadingChecking = true;
      authService
        .login(this.userInfo)
        .then((res) => {
          localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          localStorage.setItem(
            "userToken",
            JSON.stringify(res.data.meta.token)
          );
          core.showSnackbar("success", "أهلا " + res.data.data.user.name);
          window.location.reload();
          this.ifLogin = true;
        })
        .finally(() => {
          this.loadingChecking = false;
        });
    },
    checkUser() {
      this.loadingChecking = true;
      authService
        .checkUser({ user: this.userInfo.user })
        .then(() => {
          this.checkIfCheckingUser = true;
          this.$refs.loginForm.reset();
        })
        .finally(() => {
          this.loadingChecking = false;
        });
    },
    onSelect(data) {
      this.userInfo.country_code = data;
    },
    login() {
      if (this.checkIfCheckingUser) {
        this.loginUser();
      } else {
        this.checkUser();
      }
    },
  },
};
</script>
<style>
.password-toggle-icon {
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #6c757d;
}
</style>
